#system {
    background-image: linear-gradient(to bottom right,#234393, #223ca5 );
    z-index: 2;
    height: 100%;
    width: 100%;
}

.system-item {
    padding: 64px 0;
    /* max-width: 90vw; */
}

.system-h3 {
    font-family: anton;
    font-size: 4rem;
    color: #ed2328;
    font-weight: initial;
    text-shadow: 4px 4px 0px #000;
    margin-bottom: 16px;
    border-radius: 5px;
    background-color: rgb(255, 190, 13);
    padding: 16px;
    text-align: center;
}

.system-p {
    padding: 0px 64px;
    color: #ffffff;
    font-family: cuckoo;
    font-size: 1.5rem;
    font-weight: lighter;
    width: 90%;
    max-width: 1400px;
}

.system-img {
    /* width: 90%;
    max-width: 800px; */
    max-height: 300px;
    margin: 8px;
}

.system-char-img {
    width:400px;
}

.many-prinny {
    width: initial;
    max-width: 100%;
}

.many-prinnies {
    font-family: cuckoo;
    font-size: 4em;
    -webkit-text-stroke: 3px rgb(138, 218, 255);
    color: ivory;
    z-index: 2;
}

