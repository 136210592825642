@font-face {
  font-family: "anton";

  src: url(./assets/fonts/anton.ttf);
}

@font-face {
  font-family: "cuckoo";

  src: url(./assets/fonts/cuckoo.ttf);
}

@font-face {
  font-family: "staatliches";

  src: url(./assets/fonts/Staatliches-Regular.ttf);
}

.youtube-container {
  position: absolute;

  width: 70%;
  height: 70%;
  /* padding-bottom: 56.25%; */
}

.promo {
  font-family: cuckoo !important;
  letter-spacing: initial !important;
  background-color: rgb(47, 0, 175) !important;
  margin: 0px !important;
  margin-top: 8px !important;
  max-width: 90%;
}

.promo:hover {
  background-color: #ed1c28 !important;
}

.youtube {
  /* position: absolute; */
  /* top: 0;
  left: 0; */
  width: 100%;
  height: 100%;
  border: 0;
}

.top-text {
}

.top-text-sub {
  font-size: 1.5rem;
}

.show-on-med-and-lower {
  display: none;
}

.logo-container {
  width: 90%;
  /* height: 30px; */
}

.show-on-mobile-only {
  display: none !important;
}

.show-on-desktop-only {
  display: initial;
}

.App {
  /* min-width: 100%; */
  scroll-behavior: smooth;
  /* background-color: cornflowerblue; */
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
}
.col {
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.start {
  justify-content: flex-start;
}

.pad {
  padding: 32px;
}

.shadow {
  /* box-shadow: 0px 4px 4px #000; */
  -webkit-filter: drop-shadow(0px 4px 4px #000);
  filter: drop-shadow(0px 4px 4px #000);
}

.outline {
  -webkit-filter: drop-shadow(1px 1px 0 black) drop-shadow(-1px -1px 0 black);
  filter: drop-shadow(1px 1px 0 black) drop-shadow(-1px -1px 0 black);
}

.col-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.btn {
  cursor: pointer;
}

h1 {
  color: rgb(255, 255, 255);
  font-size: 3rem;
  font-family: "cuckoo", sans-serif;
  font-weight: normal;
  text-shadow: 3px 3px 0px rgb(66, 66, 66);
  margin-top: 128px;
}

h2 {
  color: #fff;
  font-size: 2rem;
  font-family: "cuckoo", sans-serif;
  font-weight: normal;
  text-shadow: 0px 4px 4px #000;
}

p {
  font-family: "staatliches";
  font-size: larger;
  color: #9aaeff;
}

.hide {
  display: none;
}

/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ NAV @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */

#nav {
  /* width: 200px; */
  /* height: 32px + 8px; */
  /* border-bottom: solid white 2px; */
  background-color: #ffd704;
  z-index: 3;
  position: fixed;
  top: 4px;
  right: 4px;
  padding: 4px;
  border-radius: 5px;
}

/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ STORY @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */

#header {
  z-index: 2;
  width: 100%;
  height: 800px;
  min-height: 115vh;
  background-color: rgba(0, 0, 0, 0.568);
  /* background-color: #1c1d45; */
}

.header-bg {
  width: 100vw;
  /* height: 100%; */
  /* background-color: #1c1d45; */
  /* position: absolute; */
  z-index: -1;
}

.header-logo {
  width: 700px;
}

.top-btns {
  /* margin-bottom: 200px; */
}

.top-btn {
  background-color: #ed1c28;
  color: #fff;
  width: 300px;
  height: 50px;
  margin: 5px;
  box-shadow: 0px 4px 4px #000;
  box-sizing: border-box;

  font-family: "anton", sans-serif;
  font-size: 1.5rem;
  letter-spacing: 0.25rem;
  font-weight: normal;
  text-shadow: 2px 2px 2px #000;
  cursor: pointer;
}

.top-btn:hover {
  background-color: #4558a7;
}

/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ CHARACTERS @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */

#characters {
  width: 100%;
  background-color: white;
  /* padding: 32px; */
}

.description-box {
  width: 500px;
  height: 100%;
  /* border: solid 1px white; */
  border-right-width: 0;
  box-sizing: border-box;
  background-color: rgb(47, 0, 175);
}

#big-char {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: #fad100;
  /* background-color: rgb(49, 51, 184); */

  max-width: 90vw;
  min-width: 300px;
  height: 500px;
  /* pointer-events: none; */
}

.big-char {
  height: 100%;
}

.big-char-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 400px;
  height: 500px;
  /* border: solid 1px white; */
  box-sizing: border-box;
  overflow: hidden;
  background-size: 100px;
}

.character-box {
  background-color: #ed1c28;
  color: #fff;
  width: 150px;
  height: 50px;
  margin: 5px;
  box-shadow: 0px 4px 4px #000;
  box-sizing: border-box;

  font-family: "anton", sans-serif;
  font-size: 1.5rem;
  letter-spacing: 0.25rem;
  font-weight: normal;
  text-shadow: 2px 2px 2px #000;
  cursor: pointer;
}

.character-box:hover {
  background-color: #4558a7;
}

/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ FOOTER @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */

#footer {
  min-width: 300px;
  width: 100%;
  background-color: #1c1d45;
}

.footer-bottom {
  background-color: black;
  width: 100%;
}

.social-icon {
  width: 64px;

  max-width: 75px;

  color: #000;

  background-color: #fff;

  border-color: #000;

  border-style: solid;

  border-top-width: 1px;

  border-left-width: 1px;

  border-right-width: 2px;

  border-bottom-width: 2px;

  font-size: 1.5rem;

  padding: 0.25rem;

  text-decoration: none;

  text-align: center;
}

.social-icon:hover {
  color: #fff;

  background-color: #ffd704;
}

.brands {
  width: 90%;
}

.brand-icon {
  width: 100px;
  padding: 16px;
  padding-bottom: 0;
}

/* %@%@%@%@%@%@%@%@%@%@%@%@%@%@%@%@%@%@%@%@%@%@%@%@%@%@% Story */

/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ STORY @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */

#story {
  min-width: 100%;
  height: 4000px;
  pointer-events: none;
}

#bgVideo {
  position: fixed;
  /* left: 0; */
  top: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -2;
  object-position: left;
  /* filter: blur(5px); */
}

.story-page {
  position: fixed;
  width: 800px;
  height: 500px;
  /* border: solid rgb(16, 0, 87) 1px; */
  top: 128px;
  perspective: 1000px;
}

.page-left {
  width: 50%;
  height: 100%;
}

.page-right {
  width: 50%;
  height: 500px;
  transform-origin: left;
  background-size: cover;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  /* transition: ease-in .03s; */
  /* background-color: rgb(255, 255, 255); */
}

.page-right-img {
  width: 100%;
  height: 100%;
}

.page-title {
  padding: 0 32px;
  color: #1c1d45;
  text-shadow: none;
  text-align: center;
  margin-bottom: 0;
  font-size: 2em;
  width: 70%;
}

.book-title {
  font-size: 2rem;
}

.book-subtitle {
  font-size: 1.4rem;
}

.page-body {
  padding: 0 32px;
  color: rgb(14, 0, 141);
  font-size: 1.6em;
  text-align: center;
}

.date {
  font-size: 1.2em;
}

.esrb-box {
  margin: 8px;
}

.esrb-subtitle {
  color: white;
  text-align: center;
  margin: 8px 0;
}

.promo-mrd {
  width: 450px;
  margin-left: auto;
  transition: 0.1s ease-in-out;
  cursor: pointer;
}

.promo-mrd:hover {
  transform: scale(1.1) rotate(4deg);
  -webkit-filter: drop-shadow(0px 4px 4px rgb(12, 255, 24));
  filter: drop-shadow(0px 4px 4px rgb(74, 250, 4));
}

.alt-cover {
  background-color: rgb(20, 40, 107);
  /* background-color: rgb(255, 217, 0); */
  padding: 8px;
  margin: 8px;
  border-radius: 5px;
}

#covers {
  padding-bottom: 64px;
}
