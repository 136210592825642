@media only screen and (max-width: 650px) {
  .show-on-med-and-lower {
    display: initial;
  }

  .header-logo {
    width: 90%;

    margin-top: 100px;
  }

  .margin {
    margin: 32px;
  }

  .show-on-mobile-only {
    display: none;
  }

  /* .show-on-desktop-only {
    display: initial;
  } */

  .flex-mobile-med {
    flex-direction: column;
  }

  #characters {
    padding: initial;
  }

  .big-char-container {
    display: flex;

    align-items: flex-end;
    min-width: 300px;
    width: 100%;
    height: 250px;
    border: solid 3px white;
    box-sizing: border-box;
    overflow: hidden;
  }

  .character-box {
    background-color: #ed1c28;
    color: #fff;
    width: initial;
    height: 32px;
    margin: 5px;
    box-shadow: 0px 4px 4px #000;
    box-sizing: border-box;
    border: noneå;
    /* font-family: 'anton', sans-serif; */
    font-size: 1rem;
    letter-spacing: initial;
    font-weight: normal;
    text-shadow: 2px 2px 2px #000;
    cursor: pointer;
    outline-color: transparent;
  }

  .character-box:focus {
    outline: -webkit-focus-ring-color auto 0px !important;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 0px;
  }

  .description-box {
    width: 100%;
    height: 300px;
    border: solid 3px white;
    border-right-width: initial;
    border-bottom-width: 0;
    box-sizing: border-box;
    background-color: rgb(47, 0, 175);
  }

  #big-char {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #faa719;
    width: 100vw;
    min-width: 300px;
    height: initial;
    /* pointer-events: none; */
  }

  .pad {
    padding: 10px;
  }

  /* h1 {
      color: #fff;
      font-size: 2rem;
      font-family: 'cuckoo', sans-serif;
      font-weight: normal;
      text-shadow: 0px 4px 4px #000;
      margin: 4px;
    } */

  h2 {
    color: #fff;
    font-size: 2rem;
    font-family: "cuckoo", sans-serif;
    font-weight: normal;
    text-shadow: 0px 4px 4px #000;
    margin: 4px;
  }

  p {
    /* font-family: 'anton'; */
    font-size: 1rem;
    color: #e6ebff;
    width: 80%;
    text-align: center;
  }

  .char-boxes {
    /* position: absolute; */
    left: 10%;
    bottom: 500px;
  }

  .story-page {
    position: fixed;
    width: 100%;
    height: 500px;
    /* border: solid rgb(16, 0, 87) 1px; */
    top: 128px;
    perspective: 1000px;
  }

  .system-p {
    padding: 0 0;
    color: #ffffff;
    /* font-family: cuckoo; */
    font-size: 1rem;
    font-weight: lighter;
    width: 100%;
  }

  .page-body {
    /* width: 60%; */
    /* color: rgb(255, 255, 255); */
    font-size: 3vw;
    text-align: center;
  }
}

@media only screen and (max-width: 450px) {
  .promo-mrd {
    width: 320px;
  }

  .youtube-container {
    position: absolute;

    width: 100%;
    height: 30%;
    /* padding-bottom: 56.25%; */
  }

  .slideInRight {
    animation: none !important;
  }

  .flex-mobile-sm {
    flex-direction: column;
  }

  .main-logo {
    width: 90%;
  }

  /* .char-boxes {
      position: absolute;
      left: 10%;
      bottom:70px;
    } */

  .next-btn {
    background-color: rgb(255, 21, 21);
    height: 32px;
    width: 48px;
    position: absolute;
    right: 32px;
    border: 0;
    /* bottom:100px; */
  }

  .top-btn {
    width: 150px;
    font-size: 1em;
  }

  #header {
    background-color: rgba(0, 35, 133, 0.452);
    height: 500px;
    min-width: 300px;
    justify-content: flex-start;
  }

  .header-bg {
    opacity: 0 !important;
  }

  .header-h1 {
    font-size: 1em;
  }

  .story-page {
    position: fixed;
    width: 100vw;
    min-width: 320px;
    height: 400px;
    /* border: solid rgb(16, 0, 87) 1px; */
    top: 128px;
    perspective: 1000px;
  }
  .page-body {
    width: 70%;
    /* color: rgb(255, 255, 255); */
    font-size: 5.5vw;
    text-align: center;
  }
  .page-right {
    width: 100%;
    height: 130%;
    background-position: center;

    background-size: contain;
    background-repeat: no-repeat;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    transform-origin: center;
    top: 0;
    /* transition: ease-in .03s; */
    /* background-color: rgb(255, 255, 255); */
  }
  .show-on-desktop-only {
    display: none;
  }

  .show-on-mobile-only {
    display: flex !important;
  }
  #system {
    /* background-image: linear-gradient(to bottom right,#4559a7c0, #223ca5b9 ); */
    /* z-index: 2; */
  }

  .system-item {
    padding: 64px 0;
    max-width: 90vw;
  }

  .system-h3 {
    /* font-family: anton; */
    font-size: 2rem;
    color: #d80000;
    font-weight: initial;
    text-shadow: none;
  }

  .system-img {
    width: 100%;
  }

  .many-prinnies {
    font-family: cuckoo;
    font-size: 2em;
    -webkit-text-stroke: 3px rgb(138, 218, 255);
    color: ivory;
  }

  #big-char {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    background-color: #fad100;
    /* background-color: rgb(49, 51, 184); */

    max-width: 90vw;
    min-width: 300px;
    height: 500px;
    /* pointer-events: none; */
  }

  .big-char {
    height: 200px;
  }

  .big-char-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 200px;
    /* border: solid 1px white; */
    box-sizing: border-box;
    overflow: hidden;
    /* background-size: 100px; */
    background-image: none !important;
  }

  .sm-btn {
    /* pointer-events: initial; */
  }

  .top-text-sub {
    font-size: 1rem;
  }

  .top-text {
    font-size: 1.5rem;
  }
}
